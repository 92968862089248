import React from 'react';
import { Typography, Card, CardHeader, CardMedia, CardActions, CardContent } from '@mui/material';
import { Link } from 'gatsby-material-ui-components';

const ActivismCard = ({ id, organization, description, learnMore, image, focus, typeGroup }) => {
    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
                component="img"
                alt={organization}
                image={image}
                sx={{ maxWidth: '100%', maxHeight: '100%', mb: 0, pb: 1 }}
            />
            <CardHeader
                titleTypographyProps={{ variant: 'subtitle1' }}
                title={organization}
                sx={{ paddingY: 1 }}
            ></CardHeader>
            <CardContent sx={{ pt: 0, pb: { xs: 0, sm: 1 }, flexGrow: 1 }}>
                <Typography variant="body1">{description}</Typography>
            </CardContent>
            <CardActions>
                <Link to={learnMore} variant="body1" sx={{ color: 'primary.main', px: 1, py: 0 }}>
                    Learn more
                </Link>
            </CardActions>
        </Card>
    );
};

export default ActivismCard;
