import React, { useState, useMemo } from 'react';
import {
    AlertTitle,
    Stack,
    Box,
    Grid,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Chip,
    ListItemText,
    Checkbox,
} from '@mui/material';
import ActivismCard from './ActivismCard';
import { encodeActivismGroupURL } from '../../utils/link-utils';

const globalCountry = 'Global';

const ActivismList = ({ activismGroups }) => {
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedFocuses, setSelectedFocuses] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);

    const { countries, focuses, types } = useMemo(() => {
        const countrySet = new Set();
        const focusSet = new Set();
        const typeSet = new Set();
        activismGroups.forEach(group => {
            for (const country of group['Country']) {
                countrySet.add(country);
            }
            for (const focus of group['Focus']) {
                focusSet.add(focus);
            }
            for (const type of group['Type of Group']) {
                typeSet.add(type);
            }
        });

        return {
            countries: [globalCountry, ...Array.from(countrySet).sort()],
            focuses: Array.from(focusSet).sort(),
            types: Array.from(typeSet).sort(),
        };
    }, [activismGroups]);

    const filteredGroups = React.useMemo(() => {
        const notFiltered = !selectedCountry && !selectedFocuses.length && !selectedTypes.length;
        if (notFiltered) {
            return activismGroups;
        }
        return activismGroups.filter(group => {
            return (
                (!selectedCountry ||
                    group['Country'].includes(selectedCountry) ||
                    group['Country'].includes(globalCountry)) &&
                (selectedFocuses.length === 0 ||
                    selectedFocuses.some(focus => group['Focus'].includes(focus))) &&
                (selectedTypes.length === 0 ||
                    selectedTypes.some(type => group['Type of Group'].includes(type)))
            );
        });
    }, [activismGroups, selectedCountry, selectedFocuses, selectedTypes]);

    return (
        <Stack
            component="section"
            alignItems="start"
            flexDirection="column"
            sx={{ mb: { xs: 9, sm: 6 }, gap: 3 }}
        >
            <AlertTitle
                sx={{ color: 'primary.main', mb: { xs: 4, sm: 0 }, fontWeight: { xs: 500 } }}
            >
                Explore the following{` `}
                {filteredGroups.length}
                {` `}climate change organizations.
            </AlertTitle>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="select-country-label" style={{ background: 'white' }}>
                            Select Country
                        </InputLabel>
                        <Select
                            id="select-country"
                            value={selectedCountry}
                            onChange={e => setSelectedCountry(e.target.value)}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 'calc(100% - 200px)' } } }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {countries.map(country => (
                                <MenuItem key={country} value={country}>
                                    {country}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="select-focuses-label" style={{ background: 'white' }}>
                            Select Focus
                        </InputLabel>
                        <Select
                            labelId="select-focuses-label"
                            id="select-focuses"
                            multiple
                            value={selectedFocuses}
                            onChange={e => setSelectedFocuses(e.target.value)}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 'calc(100% - 200px)' } } }}
                            renderValue={selected => selected.join(', ')}
                        >
                            {focuses.map(focus => (
                                <MenuItem key={focus} value={focus}>
                                    <Checkbox checked={selectedFocuses.indexOf(focus) > -1} />
                                    <ListItemText primary={focus} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="select-types-label" style={{ background: 'white' }}>
                            Select Type of Group
                        </InputLabel>
                        <Select
                            labelId="select-types-label"
                            id="select-types"
                            multiple
                            value={selectedTypes}
                            onChange={e => setSelectedTypes(e.target.value)}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 'calc(100% - 200px)' } } }}
                            renderValue={selected => selected.join(', ')}
                        >
                            {types.map(type => (
                                <MenuItem key={type} value={type}>
                                    <Checkbox checked={selectedTypes.indexOf(type) > -1} />
                                    <ListItemText primary={type} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {(selectedCountry || !!selectedFocuses.length || !!selectedTypes.length) && (
                <Grid container display="flex" gap="8px" flexWrap="wrap">
                    Selected Filters:
                    {selectedCountry && (
                        <Chip label={selectedCountry} onDelete={_ => setSelectedCountry('')} />
                    )}
                    {!!selectedFocuses.length &&
                        selectedFocuses.map(focus => (
                            <Chip
                                key={focus}
                                label={focus}
                                onDelete={_ =>
                                    setSelectedFocuses(selectedFocuses.filter(f => f !== focus))
                                }
                            />
                        ))}
                    {!!selectedTypes.length &&
                        selectedTypes.map(type => (
                            <Chip
                                key={type}
                                label={type}
                                onDelete={_ =>
                                    setSelectedTypes(selectedTypes.filter(t => t !== type))
                                }
                            />
                        ))}
                </Grid>
            )}
            <Box>
                <Grid container columnSpacing={3} rowSpacing={5}>
                    {filteredGroups.map(group => (
                        <Grid key={group.id} item xs={12} sm={6} md={4} lg={3}>
                            <ActivismCard
                                key={`group-${group.id}`}
                                id={group.id}
                                organization={group['Organization']}
                                description={group['Distilled description']}
                                learnMore={encodeActivismGroupURL(group['Organization'])}
                                image={group['Image']}
                                focus={group['Focus']}
                                typeGroup={group['Type of Group']}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Stack>
    );
};

export default ActivismList;
