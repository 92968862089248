import React from 'react';

import Layout from '@gaia/Layout';
import { Typography, Box, Stack, Divider } from '@mui/material';
import { Link } from 'gatsby-material-ui-components';
import SEO from '../components/seo';
import ActivismList from '../components/ActivismGroups/ActivismList';
import handsIn from '../images/hands-in.svg';

const ActivismGroups = ({ pageContext: { activismGroups } }) => (
    <Layout>
        <SEO title="Activism Groups" />
        <Stack
            component="section"
            alignItems="center"
            sx={{ mb: { xs: 9, sm: 4 }, flexDirection: { sm: 'row' }, gap: { sm: 3 } }}
        >
            <Box flexBasis="55%">
                <Typography variant="h1" sx={{ color: 'primary.main', mb: { xs: 2, sm: 4 } }}>
                    Climate Change Groups to Join
                </Typography>
                <Typography paragraph variant="subtitle1" sx={{ mb: 4 }}>
                    Joining or supporting climate action organizations and environmental nonprofits
                    is an excellent way to amplify the impact of your effort. By working with
                    different climate activism networks, you can find supportive communities eager
                    to offer clear direction, education, and camaraderie.
                </Typography>
                <Typography
                    variant="body1"
                    paragraph
                    sx={{ mb: 4, textAlign: { xs: 'center', sm: 'left' } }}
                >
                    Browse through the groups below to get started. Try a few out and see which is
                    the best fit for you. If you see your favorite one missing,{' '}
                    <Link
                        sx={{ color: 'primary.main' }}
                        to="/get-involved"
                        aria-label="Get Involved"
                    >
                        write to us
                    </Link>{' '}
                    and let us know.
                </Typography>
            </Box>
            <Box flexBasis="45%">
                <img src={handsIn} alt="A group of four people with hands in, working together." />
            </Box>
        </Stack>
        <Divider sx={{ mb: 8 }} />
        <ActivismList activismGroups={activismGroups} />
    </Layout>
);

export default ActivismGroups;
